<template>
  <TargetKpiDivisiForm mode="Ubah" module="Target KPI"> </TargetKpiDivisiForm>
</template>

<script>
import TargetKpiDivisiForm from './form';

const TargetKpiDivisiUpdate = {
  name: 'TargetKpiDivisiUpdate',
  components: { TargetKpiDivisiForm },
};

export default TargetKpiDivisiUpdate;
</script>
